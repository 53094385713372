// Types
import type { LocaleProps } from '@/types/config/locale'

// Configurations
import Config from '@/config'

// Automatically require all JSON files in the locales directory
// @ts-expect-error: Should expect context
const modules = require.context('@/locales/en', false, /\.json$/)
const namespaces = modules.keys().map((key: string) => key.replace('./', '').replace('.json', ''))

const LocaleConfig: LocaleProps = {
	default: process.env.APP_LOCALE || 'en',
	list: ['en', 'es', 'ar', 'fa'],
	fallbackLng: 'en',
	ns: namespaces,
	cookieName: `${Config.shortName}-i18next`
}

export default LocaleConfig
