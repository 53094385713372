'use client'

import { createContext, useContext } from 'react'

// Types
import type { TitleContextProps } from '@/types/helpers/context/title'

// Create Context
const TitleContext = createContext<TitleContextProps>({
	title: '',
	setTitle: () => {}
})

export const useTitle = () => useContext(TitleContext)
export default TitleContext
