import { Typography } from '@mui/material'

// Types
import type { TitleProps } from '@/types/components/atoms/shapes/title'

// Helpers
import { useTranslation } from '@/helpers/i18n/client'
import { useTitle } from '@/helpers/context/title'

// Utils
import Camelize from '@/utils/camelize'

// Metadata
const TitleAtom = (props: TitleProps) => {
	// Props
	const { lng } = props

	// Variables
	const { t } = useTranslation(lng)
	const { title } = useTitle()

	return (
		<Typography component="h1" fontSize={{ xs: 18, md: 24 }} fontWeight={600}>
			{t(Camelize(title.replace(` | ${t('common:app.name')}`, '')))}
		</Typography>
	)
}

export default TitleAtom
